import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../../components/Common/Header';
import BlueLeftArrow from '../../../assets/images/BlueLeftArrow.svg';
import './style.scss';
import VillageFilterBox from '../../../components/VillageFilterBox';
import NoDataFound from '../../../components/Common/NoDataFound';
import { setApiError } from '../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../services/commonUtils';
import { useDispatch } from 'react-redux';
import { commonService } from '../../../services/common';
import GlobalConfig from '../../../config/globalconfig';
import PreLoader from '../../../components/Common/Preloader';
import CustomModal from '../../../components/Common/Modal';
import DidisTab from './DidisTab';
import MissionTab from './MissionTab';
import LivelihoodTab from './Livelihood';
import LanguageTab from './Language';

export const ProgramContext = createContext();

const ProgramDetailsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { detail } = location.state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [widgetArrayList, setWidgetArrayList] = useState([]);
    const dispatch = useDispatch();
    const [activeVillageId, setActiveVillageId] = useState();
    const [activeWidgetName, setActiveWidgetName] = useState('Didis')
    const [toastMessage, setToastMessage] = useState('');

    const handleSetToastMessage = (message) => {
        setToastMessage(message);
    };
    

    const programDetailsWidget = (widgetArrayList) => {
        if (widgetArrayList.length > 0) {
            const villageData = widgetArrayList.map((item, ind) => (
                <VillageFilterBox
                    key={`village-${ind}`}
                    didiCount={item?.count}
                    villageName={item?.name}
                    villageId={item?.widgetItemId}
                    isActive={activeVillageId === item?.widgetItemId}
                    handleFilter={(id) => handleWidgetClick(id, item?.name)}
                    subTitle='Total:'
                />
            ));
            return villageData;
        } else {
            return (<NoDataFound text='No Village Found' sizeClass='small' />);
        }
    };

    const fetchProgramWidgetList = async (isActive) => {
        if(isActive){
            setActiveVillageId(isActive)
        }
        setIsLoading(true)
        try {
            const response = await commonService.fetchProgramWidgetList(detail?.programId);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                let resData = res?.data;
                resData = resData.map((item, index) => {
                    return { ...item, widgetItemId: index + 1 };
                }).filter((item)=>  !["Programs"].includes(item.name));
                setWidgetArrayList(resData);
            }
            else {
                dispatch(setApiError())
                setWidgetArrayList([])
            }
            setIsLoading(false)
        } catch (error) {
            setWidgetArrayList([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleWidgetClick = (id, pgName) => {
        setActiveWidgetName(pgName);
        setActiveVillageId(id);

    };

    useEffect(() => {
        fetchProgramWidgetList();
    }, [detail?.programId]);

    useEffect(() => {
        if (widgetArrayList.length > 0 && !activeVillageId ) {
            setActiveVillageId(widgetArrayList[0]?.widgetItemId);
        }

    }, [widgetArrayList]);

    useEffect(()=>{
        handleSetToastMessage('')
    },[activeVillageId])

    const renderWidgetContent = () => {
        switch (activeWidgetName) {
            case 'Didis':
                return <DidisTab detail={detail} activeWidgetId={widgetArrayList[0]?.widgetItemId} activeWidgetName={activeWidgetName} />
            case 'Missions':
                return <MissionTab detail={detail} />
            case 'Livelihoods':
                return <LivelihoodTab detail={detail} />
            case 'Languages':
                return <LanguageTab detail={detail} />
            default:
                return (
                    <div className='program-details-noData-outer'>
                        <div className='program-details-noData-sec'>
                            <span>No data available</span>
                        </div>
                    </div>
                );
        }
    };


    return (
        <ProgramContext.Provider value={{ activeVillageId, fetchProgramWidgetList, handleSetToastMessage, toastMessage }}>
        <div className="inner-page program-details fixed-header">
            <Header />
            {isLoading ? <PreLoader /> :
            <>
            <div className='container program-details-container'>
                <div className='program-details-header-sec'>
                    <img
                        className='program-back'
                        src={BlueLeftArrow}
                        onClick={() => navigate(-1)}
                        height={21}
                    />
                    <span>
                        Program Name: <b>{detail?.programName}</b> | Program ID: <b>{detail?.programId}</b> | State: <b>{detail?.state}</b>
                    </span>
                </div>
                <div className='program-details-body-sec'>
                    <div className='program-details-left-widget'>
                        {programDetailsWidget(widgetArrayList)}
                    </div>
                    <div className='program-details-right-sec'>
                    {renderWidgetContent()}
                    </div>
                </div>
            </div>
            <CustomModal />
            
            </>
           }
        </div>
        </ProgramContext.Provider>
    );
};

export default ProgramDetailsPage;
