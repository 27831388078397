import React, { useContext, useEffect, useState } from 'react';
import { commonService } from '../../../../services/common';
import { useDispatch } from 'react-redux';
import { catchErrorHandler } from '../../../../services/commonUtils';
import { setApiError } from '../../../../storeManager/common/actions';
import GlobalConfig from '../../../../config/globalconfig';
import SearchBar from '../../../../components/SearchBar';
import NoDataIcon from '../../../../assets/images/language.png';
import './style.scss';
import Table from '../../../../components/Common/Table';
import TableColumns from './TableColumns';
import PreLoader from '../../../../components/Common/Preloader';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import ToastMsg from '../../../../components/Common/ToastMsg';
// import MapLanguage from './MapLanguage';
import CustomModal from '../../../../components/Common/Modal/VoDidiMappingFileUpload/customModal';
// import AssetIncomeModal from './AssetIncomeModal';
import { ProgramContext } from '..';

const LanguageTab = ({ detail }) => {

    const { toastMessage, handleSetToastMessage } = useContext(ProgramContext);
   
    const [languageList, setLanguageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isMapLanguageCheck, setIsMapLanguageCheck] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [languageListValue, setLanguageListValue] = useState('');
    const [searchedDataList, setSearchedDataList] = useState([]);

    const dispatch = useDispatch();

    const fetchLanguageList = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonService.mappedLanguageList(id);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setLanguageList(res?.data);
            } else {
                dispatch(setApiError());
                setLanguageList([]);
            }
        } catch (error) {
            setLanguageList([]);
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        } finally {
            setIsLoading(false);
        }
    };

    
    const handleSearchFilter = (value) => {
        let filteredList = []
        setLanguageListValue(value)
        filteredList = languageList.filter(x => x.language.toLowerCase().includes(value.toLowerCase()))
        setSearchedDataList(filteredList)
    }

    const programLanguageMapUnmap = async(id, status) => {
        debugger
        setIsLoading(true);
        const payload = {
            programId: detail?.programId,
            languageCodes: id,
            flag: status,
        };
        try {
            const response = await commonService.programLanguageMapUnmap(payload);
            const res = response?.data
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                handleSetToastMessage(res?.data);
                if(status) {
                    fetchLanguageList(detail?.programId)
                }
            // }else if(res?.status === GlobalConfig.MESSAGES_TYPES.FAIL){
            //     dispatch(openModal(MODALS.LIVELIHOOD_DELETE_MODAL,
            //         {
            //             parentClass: "super-admin-delete-info center-modal confirmation-modal",
            //             modalTitle: `Livelihood ID: ${ids}`,
            //             status : res?.status,
            //             msg : res?.data,
            //             type: 'add',
            //             referenceId: id,
            //         }))
            } else {
                dispatch(setApiError());
                handleSetToastMessage('');
            }
        } catch (error) {
            handleSetToastMessage('');
            console.error(error);
            catchErrorHandler(error, dispatch(setApiError()));
        } finally {
            setIsLoading(false);
        }
        // let mappedLanguages = detail?.mappedLanguages
        // if (mappedLanguages) {
        //     mappedLanguages.forEach(async (item) => {
        //         await programLanguageMapUnmap(item.id, detail?.programId)
        //     })
        // }
        // fetchLanguageList(detail?.programId)
    }
    
   
    const handleLanguageMapping = () => {
        dispatch(openModal(MODALS.PROGRAM_LANGUAGE_MAPPING_MODAL,
            {
                parentClass: "center-modal language-mapping-modal",
                modalTitle: 'Map Language',
                detail: detail,
                programLanguageMapUnmap: programLanguageMapUnmap,
                // type: 'add',
                // referenceId: referenceId,
                // roleName: roleName
            }))
        }

   
    const handleUnmap = (id) => {
        if(id === 'en') {
            return;
        }
        dispatch(openModal(MODALS.SUPER_ADMIN_DELETE_INFO_MODAL, {
            parentClass: "super-admin-delete-info center-modal confirmation-modal",
            btnText: "Unmap",
            confirmAction: programLanguageMapUnmap,
            btnClass: "livelihood-delete",
            id: [id],
            status: false,
            modalTitle: 'Are you sure to unmap language?',
            message: `Un-mapping Language Code: ${id}`,
        }));
    }
    useEffect(() => {
        fetchLanguageList(detail?.programId);
    }, []);

    if (isLoading) {
        return <PreLoader />;
    }

    return ( 
        <>
                <div className={languageList.length > 0 ? 'language-details-right-sec' : 'language-details-right-sec language-details-optional'}>
                    {languageList?.length > 0 && (
                        <div className='language-details-right-title'>
                            <span>Showing {languageListValue === '' ? languageList.length : searchedDataList.length} languages</span>
                        </div>
                    )}
                    <div className='language-header-outer'>
                        <SearchBar className="language-search-bar" placeholder='Search language' value={languageListValue} handleOnChange={(e) => handleSearchFilter(e.target.value)}
/>
                        <button className='primary-btn language-map-btn' onClick={handleLanguageMapping}>
                            + Map language
                        </button>
                    </div>
                </div>
                {languageList.length > 0 ? (
                    <div className='language-details-lower-section'>
                        <Table className='language-details-table pending-table mapping-list '
                            columns={TableColumns({handleUnmap})}
                            data={languageListValue ? searchedDataList : languageList}
                            valueKey='langCode'
                            checkbox={false}
                            selectAll={false}
                        />
                    </div>
                ) : (
                    <div className='language-details-noData-outer'>
                        <div className='language-details-noData-sec'>
                            <img src={NoDataIcon} alt="No Data" />
                            <span>No languages have been mapped to this program yet</span>
                        </div>
                    </div>
                )}
                {toastMessage && <ToastMsg otpMsg={toastMessage} success={true} />}
               
            </>
)
};

export default LanguageTab;
